import {ConfirmDialog} from "primereact/confirmdialog"
import React, {useContext, useEffect, useState} from "react"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"
import {useNavigate} from "react-router-dom"
import {useMutation} from "react-query"
import {patchCandidateConsent} from "../../networks/patchNetwork"
import {candidateAppContext} from "../../pages/MainPageCandidate"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics"
import {UserRoles} from "../../models/Names"

type CandidateConsentDialogProps = {
    token: string,
    isConsentGiven: boolean
}
const CandidateConsentDialog = (props: CandidateConsentDialogProps) => {
    const context = useContext(candidateAppContext)
    const navigate = useNavigate()
    const [dialogVisible, setDialogVisible] = useState(false)
    const {mutate} = useMutation({
        mutationFn: patchCandidateConsent,
        onSuccess: (): void => {
            setDialogVisible(false)
            context.setCandidateData({...context.candidateData, isConsentGiven: true})
            analyticsEvent(AnalyticsEventCategory.CANDIDATE_CONSENT, {
                role: UserRoles.CANDIDATE,
                candidateId: context.candidateData.id
            })
        },
        onError: (): void => {
            setDialogVisible(true)
            analyticsEvent(AnalyticsEventCategory.CANDIDATE_CONSENT, {
                role: UserRoles.CANDIDATE,
                candidateId: context.candidateData.id,
                error: "Error while sending consent"
            })
        }
    })

    useEffect((): void => {
        if (!props.isConsentGiven) {
            setDialogVisible(true)
        }
    }, [props.isConsentGiven])

    const sendConsent = (): void => {
        mutate({tempToken: props.token})
    }
    const rejectConsent = (): void => {
        navigate(`/${RouteFrontEnd.ERROR_PAGE}`)
    }

    const consentText = (
        <span>
            Are you giving consent to Career Verification to collect and process your personal data for verifying your work experience and creating your professional profile? You have the right to access, modify, or delete your data at any time. For more details, please review our<button onClick={() => window.open(`/${RouteFrontEnd.POLICY}`, '_blank')} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Privacy Policy.</button>
        </span>
    )

    return (
        <ConfirmDialog
            header="Consent" visible={dialogVisible}
            message={consentText}
            icon="pi pi-exclamation-triangle"
            acceptClassName="p-button-danger"
            rejectClassName="p-button-secondary"
            accept={sendConsent}
            reject={rejectConsent}
            onHide={() => setDialogVisible(false)}
            style={{ width: '400px' }}
        />
    )
}

export default CandidateConsentDialog