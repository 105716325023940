import React, {SyntheticEvent} from 'react'
import './PolicyPage.css'
import logo from "../resources/LogoSymbolOnly.png"
import {useNavigate} from "react-router-dom"

const PolicyPage = () => {
    const navigate = useNavigate()

    const onLogoClick = (event: SyntheticEvent) => {
        event.preventDefault()
        navigate(-1)
    }

    const start = <img
        src={logo}
        alt="logo"
        height="40"
        className="logo"
        onClick={onLogoClick}
        style={{cursor: 'pointer'}}
    ></img>

    return (
        <div className="policy-page">
            <header className="policy-header">
                {start}
                <h1>Career Verification</h1>
            </header>
            <main className="policy-content">
                <h2>Privacy Policy</h2>
                <p><strong>Effective Date:</strong> 05th January 2024</p>
                <h3>1. Introduction</h3>
                <p>Career Verification ("we," "us," "our") is committed to protecting your personal data and ensuring transparency in how we process your information. This Privacy Policy explains how we collect, use, and protect your data when you use our platform.</p>
                <h3>2. Data We Collect</h3>
                <p>We may collect the following types of personal data:</p>
                <ul>
                    <li><strong>Identification Information:</strong> Name, surname, and email address.</li>
                    <li><strong>Employment Information:</strong> Work history, job titles, references, and related details.</li>
                    <li><strong>Platform Usage Data:</strong> Technical and analytical information about your interactions with our platform.</li>
                    <li><strong>Communication Data:</strong> Any information you provide when contacting us.</li>
                </ul>
                <h3>3. Purpose of Data Processing</h3>
                <p>We process your data for the following purposes:</p>
                <ul>
                    <li>To verify your work experience and create your professional profile.</li>
                    <li>To provide our services and communicate with you regarding your account.</li>
                    <li>To ensure compliance with legal and regulatory requirements.</li>
                    <li>To improve and personalize our platform based on user interactions.</li>
                </ul>
                <h3>4. Legal Basis for Processing</h3>
                <p>Under the applicable regulations, we process your data based on the following legal grounds:</p>
                <ul>
                    <li><strong>Consent:</strong> At the time of registration, when you provide explicit consent we will process your data.</li>
                    <li><strong>Contractual Obligation:</strong> To deliver the services you requested.</li>
                    <li><strong>Legitimate Interests:</strong> To improve our services and ensure platform security.</li>
                    <li><strong>Compliance with Local Laws:</strong> Adhering to privacy regulations in jurisdictions worldwide, we comply with the General Data Protection Regulation (GDPR) for the European Union, the California Consumer Privacy Act (CCPA) in the United States, the Personal Information Protection and Electronic Documents Act (PIPEDA) in Canada, and other applicable local data protection laws.</li>
                </ul>
                <h3>5. Data Sharing</h3>
                <p>We do not sell your data to third parties under any circumstances. Data sharing only occurs when required by legal obligations, or with service providers assisting us in delivering our platform:</p>
                <ul>
                    <li>To comply with legal obligations, such as responding to law enforcement requests, court orders, or legal processes, or to enforce our terms, including preventing misuse of the platform, resolving disputes, and protecting the rights and safety of our users.</li>
                    <li>With service providers who assist us in delivering our platform. These may include cloud hosting services (e.g., for data storage), analytics tools (to understand platform usage), email providers (for communication), and customer support platforms. All third parties are bound by strict confidentiality agreements to ensure the security and privacy of your data.</li>
                </ul>
                <h3>6. Data Retention</h3>
                <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this policy or to comply with legal requirements. If you delete your account, we will securely remove your data within a maximum of 10 days.</p>
                <h3>7. Your Rights</h3>
                <p>Depending on your jurisdiction, you have the following rights regarding your personal data:</p>
                <ul>
                    <li><strong>Access:</strong> Request access to the data we hold about you.</li>
                    <li><strong>Rectification:</strong> Request corrections to inaccurate or incomplete data.</li>
                    <li><strong>Erasure:</strong> Request deletion of your data under certain conditions.</li>
                    <li><strong>Restriction:</strong> Restrict processing of your data under specific legal conditions, such as when disputing the accuracy of your personal information.</li>
                    <li><strong>Data Portability:</strong> Receive a copy of your data in a structured, machine-readable format.</li>
                    <li><strong>Objection:</strong> Users may object to processing based on legitimate interests, except where consent is the sole basis for processing their data.</li>
                </ul>
                <p>To exercise any of these rights, please contact us at info@careerverification.com.</p>
                <h3>8. Data Security</h3>
                <p>We take appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes encryption, access controls, and regular security audits.</p>
                <h3>9. International Data Transfers</h3>
                <p>We currently do not transfer your data outside the European Economic Area (EEA). All data is securely stored on servers located within the EU, ensuring compliance with the General Data Protection Regulation (GDPR).</p>
                <h3>10. Cookies and Tracking Technologies</h3>
                <p>Our platform uses cookies to enhance your user experience. For more details, please review our Cookie Policy.</p>
                <h3>11. Changes to This Policy</h3>
                <p>We may update this Privacy Policy from time to time. Changes will be communicated via email or through our platform. Please review this policy periodically for updates.</p>
                <h3>12. Contact Us</h3>
                <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
                <p>Career Verification<br />
                Email: <a href="mailto:info@careerverification.com">info@careerverification.com</a></p>
            </main>
        </div>
    );
};

export default PolicyPage;