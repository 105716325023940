import {Card} from "primereact/card"
import ExperienceListCertificateSegment from "./ExperienceListCertificateSegment"
import {useParams} from "react-router-dom"
import {useQuery} from "react-query"
import {NetworkEndpoints} from "../../models/NetworkEndpoints"
import {getQueryWithoutAuthNetwork} from "../../networks/getNetwork"
import {useEffect, useState} from "react"
import {countryNameForCode} from "../../utils/CountryFunctions"
import {ExperienceCertificate} from "../../models/AllModels"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics";
import {UserRoles} from "../../models/Names";
import usePageAnalytics from "../../hooks/usePageAnalytics";
import {RouteFrontEnd} from "../../models/RouteFrontEnd";

const CertificatePage = () => {
    const {sharedId} = useParams()
    const networkResponse= useQuery([NetworkEndpoints.CERTIFICATION, sharedId], getQueryWithoutAuthNetwork, {staleTime: 60000, enabled: !!sharedId})

    const [country, setCountry] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [experiences, setExperiences] = useState<ExperienceCertificate[]>([])
    const [email, setEmail] = useState<string>('')

    useEffect(() => {
        if (!!networkResponse.data) {
            setCountry(countryNameForCode(networkResponse.data.country) ?? '')
            setName(networkResponse.data.name)
            setExperiences(networkResponse.data.experiences)
            setEmail(networkResponse.data.email)
            analyticsEvent(AnalyticsEventCategory.VIEW_CERTIFICATE, {
                role: UserRoles.UNKNOWN,
                candidateId:  networkResponse.data.shareId
            })
            // networkResponse.data.experiences.sort((a, b) => {
            //     if(a.jobOngoing && !b.jobOngoing) return -1
            //     if(!a.jobOngoing && b.jobOngoing) return 1
            //     return Number(b.jobStartDate) - Number(a.jobStartDate)
            // })
        }
    }, [networkResponse.data])

    usePageAnalytics("/"+RouteFrontEnd.CERTIFICATE+"/"+networkResponse?.data?.shareId, "CertificatePage")

    return (
        <div>
            <Card className="p-card pt-3 lg:pl-3 md:pl-1 lg:m-4 md:m-1 shadow-3">
                <div className=''>
                    <h1>{name}</h1>
                    <h3>Experience Confirmation Certificate</h3>
                    <p>Country: {country}</p>
                </div>
                <ExperienceListCertificateSegment experiences={experiences} />
            </Card>
        </div>
    )
}
export default CertificatePage