import {ga4Event, ga4LoginEvent, ga4PageView} from "../../utils/ga4/ga4analitycs"
import {UserRoles} from "../../models/Names"

export const analyticsPageView = (url: string, pageTitle: string) => {
    ga4PageView(url, pageTitle)
}

export const analyticsLoginEvent = () => {
    ga4LoginEvent()
}


export const analyticsEvent = (category: AnalyticsEventCategory, params: AnalyticsEventParams) => {
    ga4Event(category, params)
}

export type AnalyticsEventParams = {
    role?: UserRoles
    agencyId?: string
    candidateId?: string
    experienceId?: string
    verificationId?: string
    error?: string
    comment?: string
}

export enum AnalyticsEventCategory {
    LOGOUT = "logout",
    REGISTER = "register",
    RESET_PASSWORD = "reset_password",
    RESET_PASSWORD_SENT = "reset_password_sent",
    SEND_VERIFICATION = "send_verification",
    CREDIT_ADD = "credit_add",
    INVITE_ADMIN_USER = "invite_admin_user",
    INVITE_AGENCY_USER = "invite_agency_user",
    INVITE_CANDIDATE = "invite_candidate",
    LOGIN_AS_AGENCY = "login_as_agency",
    CREATE_AGENCY = "create_agency",
    UPDATE_AGENCY = "update_agency",
    DELETE_AGENCY = "delete_agency",
    CREATE_CANDIDATE = "create_candidate",
    UPDATE_CANDIDATE = "update_candidate",
    DELETE_CANDIDATE = "delete_candidate",
    CANDIDATE_CONSENT = "candidate_consent",
    CREATE_EXPERIENCE = "create_experience",
    UPDATE_EXPERIENCE = "update_experience",
    DELETE_EXPERIENCE = "delete_experience",
    CREATE_VERIFICATION = "create_verification",
    DELETE_VERIFICATION = "delete_verification",
    APPROVE_BY_MANAGER = "approve_by_manager",
    REJECT_BY_MANAGER = "reject_by_manager",
    APPROVE_BY_ADMIN = "approve_by_admin",
    REJECT_BY_ADMIN = "reject_by_admin",
    COPY_BUTTON_CLICK = "copy_button_click",
    VIEW_CERTIFICATE = "view_certificate",
}