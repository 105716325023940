import StatusesPreview from "../MainApp/StatusesPreview"
import {AttestationStatus, Verification} from "../../models/AllModels"
import {useMutation, useQueryClient} from "react-query"
import {patchSendVerification} from "../../networks/patchNetwork"
import VerificationDelete from "../MainApp/experience/verification/VerificationDelete"
import {candidateAppContext} from "../../pages/MainPageCandidate"
import {useContext, useEffect, useRef} from "react"
import {ProgressSpinner} from "primereact/progressspinner"
import {refreshOnVerificationListChange} from "../../utils/RefreshFunctions"
import {analyticsEvent, AnalyticsEventCategory} from "../../services/analytics/analytics"
import useRoleStatus from "../../hooks/useRoleStatus"
import {UserRoles} from "../../models/Names"
import SendVerificationButton from "./SendVerificationButton"
import {OverlayPanel} from "primereact/overlaypanel"
import {splitStringByNewLine} from "../../utils/SplitingStringByNewLine"
import {NeltQuestionsSerbian} from "../../models/NeltQuestions"

type Props = {
    verification: Verification
}
const VerificationListItem = (props: Props) => {
    const {candidateData} = useContext(candidateAppContext)
    const tempToken = candidateData?.id ?? undefined
    const roleStatus = useRoleStatus([UserRoles.CANDIDATE, UserRoles.AGENCY, UserRoles.ADMIN], tempToken)
    const queryClient = useQueryClient()
    const mutateVerification = useMutation({
        mutationFn: patchSendVerification,
        onSuccess: () => {
            void refreshOnVerificationListChange(queryClient, candidateData.candidateId, '' + props.verification.experienceId, tempToken)
            analyticsEvent(AnalyticsEventCategory.SEND_VERIFICATION, {
                role: roleStatus.role,
                candidateId: candidateData.candidateId,
                verificationId: "" + props.verification._id
            })
        },
        onError: () => {
            analyticsEvent(AnalyticsEventCategory.SEND_VERIFICATION, {
                role: roleStatus.role,
                candidateId: candidateData.candidateId,
                verificationId: "" + props.verification._id,
                error: "Sending Verification Failed"
            })
        }
    })

    const op = useRef<OverlayPanel>(null)

    useEffect(() => {
        op.current?.hide()
    }, [])

    const showingComment = (comment: string) => {
        return  splitStringByNewLine(comment).map((t, i) => {
                if (NeltQuestionsSerbian.includes(t)) {
                    return <div key={i} className="text-black-alpha-40 text-xs">{t}</div>
                } else {
                    return <div key={i} className="text-black-alpha-400 pb-2">{t}</div>
                }
            }
        )
    }

    const sendVerification = () => {
        mutateVerification.mutate({
            verificationId: props.verification._id,
            refereeEmail: props.verification.refereeEmail,
            tempToken: tempToken
        })
    }

    const displayHeader = <div
        className="font-bold m-2 text-black-alpha-80">{props.verification.refereeName}</div>

    if (mutateVerification.isLoading) return <ProgressSpinner/>

    return (
        <>
            <div className="p-card text-sm lg:pl-2 md:pl-1 pt-2 lg:m-4 md:m-0 shadow-2">
                {displayHeader}
                <div className="grid align-items-start vertical-align-top">
                    <div className="col-8 ml-2 text-black-alpha-80">
                        <div>Email: <b className="text-black-alpha-40">{props.verification.refereeEmail}</b>
                        </div>
                        {!!props.verification.refereeComment &&
                            <div className="mt-2">Comment: <b onClick={(e) => op.current?.toggle(e)}
                                                              className="text-black-alpha-40">{showingComment(props.verification.refereeComment)}</b>
                            </div>
                        }
                        {!!props.verification.refereeJobTitle &&
                            <div className="mt-2">Title: <b
                                className="text-black-alpha-40">{props.verification.refereeJobTitle}</b>
                            </div>
                        }
                    </div>
                    <div className="col-1 w-0 vertical-align-top"/>
                    <VerificationDelete id={props.verification._id}
                                        experienceId={props.verification.experienceId}
                                        locked={props.verification.locked ?? false}/>
                    {props.verification.attestationStatus === AttestationStatus.TimedOut &&
                        <SendVerificationButton refereeComment={props.verification.refereeComment}
                                                locked={props.verification.locked}
                                                sendVerification={sendVerification}/>}
                </div>
                <StatusesPreview statuses={{
                    attestationStatus: props.verification.attestationStatus,
                    checkStatus: props.verification.checkStatus,
                    signifyInspectionStatus: props.verification.inspectionStatus,
                    signatureStatus: props.verification.signatureStatus
                }}/>
            </div>

            {props.verification.refereeComment && <OverlayPanel
                ref={op}
                showCloseIcon
                id="overlay_panel"
                style={{width: "450px"}}
                className="overlaypanel-demo"
            >{showingComment(props.verification.refereeComment)}
            </OverlayPanel>}
        </>
    )
}

export default VerificationListItem