import usePageAnalytics from "../../hooks/usePageAnalytics"
import {RouteFrontEnd} from "../../models/RouteFrontEnd"

const ThankYou = () => {

    usePageAnalytics("/"+RouteFrontEnd.MANAGER, "ManagerThankYouPage")

    return(
        <>
            <br />
            <div className='col-8 bg-indigo-600 text-white-alpha-70 p-4 md:text-m lg:text-xl border-round-2xl align-items-center text-left ml-5'>

                <p>Thank you for verifying work experience on Career Verification! Your contribution helps build a more transparent and trustworthy hiring process.</p>
                <p>✨ Want to secure your own references? Register now and start collecting verified recommendations for your career!</p>
                <p>👉 <a href="https://www.careerverification.com/#free-work-verification-apply-form">Sign Up Now</a></p>
            </div>
        </>
    )
}
export default ThankYou